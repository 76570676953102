<style>
  .cover-list{
    overflow: hidden;
    margin: 0px -6px 0;
    padding-top: 5px;
  }
  .cover-list li{
    float: left;
    width: 33.33%;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 10px;
    padding: 0 6px;
    box-sizing: border-box;
  }
  .cover-list li:nth-of-type(3n){
    margin-right: 0;
  }
  .cover-list li a{
    display: block;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
  }
  .cover-list li img{
    width: 100%;
    display: block;
  }
  .cover-list li a.on::after{
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    background: url("../../assets/image/selected01.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .pagebgimg{
      position: fixed;
      top: 0;
      z-index: -1;
      width: 100%;
height: 100%;
    }
    .pagebgimg>img{
      width: 100%;
height: 100%;
    }
</style>
<template>
  <div>
    <header-title titleCon="添加封面" titleBtnStr="保存" @titleLeftBtn="titleFnReturn" @titleFnBtn="submitFace"></header-title>
    <div class="content book-cover">
      <ul class="cover-list">
        <li v-for="(item, index) in coverList" :key="index">
          <a :class="active === index ? 'on': ''" @click="changeFace(index)">
            <span><img :src="item.coverPath + '?x-oss-process=image/resize,m_fixed,h_800,w_600'" :title="item.coverTitle"></span>
          </a>
        </li>
      </ul>
    </div>
<div class="pagebgimg">
          <img :src="pagebg" alt="">
    </div>
  </div>
</template>
<script>
import headerTitle from '../../components/header/header-title-transparent.vue'
export default {
  components: {
    headerTitle
  },
  data () {
    return {
      windowHeight: document.documentElement.clientHeight,
      pagebg: require('../../assets/image/pagebgsm.png'),
      active: 0,
      coverList: [
        {
          coverPath: 'https://www.zuok.cn/down/upload/book/16000402249737/20170810120315/m.jpg?x-oss-process=image/resize,m_fixed,h_270,w_200,limit_0',
          alt: '重生1999'
        },
        {
          link: 'https://www.zuok.cn/down/upload/book/15000032112116/20170116153020/m.jpg?x-oss-process=image/resize,m_fixed,h_270,w_200,limit_0',
          alt: '绝世兵王在都市'
        },
        {
          link: 'https://www.zuok.cn/down/upload/dbook/16000349655866/20180718094217/m.jpg?x-oss-process=image/resize,m_fixed,h_270,w_200,limit_0',
          alt: '假戏真做'
        },
        {
          link: 'https://www.zuok.cn/down/upload/book/14480853629980/20170307161349/m.jpg?x-oss-process=image/resize,m_fixed,h_270,w_200,limit_0',
          alt: '与你共今辰'
        },
        {
          link: 'https://www.zuok.cn/down/upload/book/16000007941762/20170503133816/m.jpg?x-oss-process=image/resize,m_fixed,h_270,w_200,limit_0',
          alt: '闪婚新妻宠上天'
        },
        {
          link: 'https://www.zuok.cn/down/upload/book/14371036141011/14371036141053/20150728094414/m.jpg?x-oss-process=image/resize,m_fixed,h_270,w_200,limit_0',
          alt: '素手毒医：公主归来'
        }
      ],
      making: ''
    }
  },
  created () {
    // 适配背景图片
    if (this.windowHeight > 736) {
      this.pagebg = require('../../assets/image/pagebgbig.png')
    }
    this.making = this.$route.query.making
  },
  computed: {
  },
  mounted () {
    // if (this.$store.state.coverindex) {
    //   this.active = this.$store.state.coverindex
    // }
    var that = this
    this.axios.get('/v1/coverList').then((res) => {
      if (res.data.head.flag === 0) {
        this.coverList = res.data.content.coverList
        this.coverList.forEach(function (event, index) {
          if (event.id === parseInt(localStorage.getItem('coverId'))) {
            that.active = index
          }
        })
      } else {
        this.$toast(res.data.head.desc)
      }
    })
  },
  methods: {
    titleFnReturn () {
      // this.$router.go(-1)
      this.$router.push({ path: '/bookEdit', query: { bookId: this.$route.query.bookId } })
    },
    // 封面选择
    changeFace (index) {
      this.active = index
    },
    // 选择封面完成
    submitFace () {
      // this.$store.state.coverindex = this.active
      this.$store.state.bookInfo.cover.coverId = this.coverList[this.active].id
      this.$store.state.bookInfo.cover.coverImg = this.coverList[this.active].coverPath
      if (this.making === 1) {
        this.$router.push({ path: '/bookEdit', query: { bookId: this.$route.query.bookId, making: this.making } })
      } else {
        this.$router.push({ path: '/bookEdit', query: { bookId: this.$route.query.bookId } })
      }

      localStorage.setItem('coverId', this.coverList[this.active].id)
    }
  }
}
</script>
