<style>
  .header{
        position: sticky;
        top: 0;
        background: RGBA(225, 227, 249, 1)!important;
    height: 45px;
    line-height: 45px;
    z-index: 1001;
    /* border-bottom: 1px solid #eeeeee; */
  }
  .header .title .titlecon {
    text-align: center;
    width: 100%;
    font-size: 1.1rem;
    color: #000;
  }
  .titlecon {
    padding: 0 102px;
    box-sizing: border-box;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
  .header .title .return,.header .title .fr{
    position: absolute;
    top: 0;
  }
  .header .title .return{
    left: 15px;
  }
  .header .title .return img{
    height: 24px;
    width: 24px;
    vertical-align: middle;
  }
  .header .title .fr{
     float: none;
     right: 15px;
     height: 45px;
     line-height: 45px;
     color: #547CFF;
  }
  .header .title .fr.frLf{
    float: none;
    right: 60px;
    color: #656fef;
    background: none;
    line-height: 45px;
    height: 45px;
    padding: 0;
    font-size: 0.875rem;
  }
</style>
<template>
  <div class="header">
    <div class="section title">
      <div class="titlecon">{{titleCon}}</div>
      <a class="return" @click="goReturn"><img src="../../assets/image/return_top2.png"></a>
      <a class="fr frLf" v-if="titleBtnStrLf !== 'hide'" @click="titleFnLf">{{titleBtnStrLf}}</a>
      <a class="fr" @click="titleFn">{{titleBtnStr}}</a>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    'titleCon', 'titleBtnStr', 'titleBtnStrLf'
  ],
  data () {
    return {
    }
  },
  components: {
  },
  mounted () {},
  methods: {
    goReturn () {
      // this.$router.go(-1)
      this.$emit('titleLeftBtn')
    },
    titleFn () {
      this.$emit('titleFnBtn')
    },
    titleFnLf () {
      this.$emit('titleFnBtnLf')
    }
  }
}
</script>
